/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import $ from 'jquery';


import "./formrelation.css";

class Formrelation extends React.Component{
    //javascript
    state = {
      pl: "",
      pw: "",
      D: "",
      Fc: "",
      Fy: "",
      v: "",
      R: "",
      Dout: "",
      Din: "",
      Rout: "",
      Rin: "",
      Fcd: "",
      h: "",
      b: "",
      t: "",
      result: "",
    }
    handleInputChange = event => {
      const target = event.target
      const value = target.value
      const name = target.name
      const value2 = value.replace(",",".");
      
      this.setState({
        [name]: value2,
      })
    }
  
    handleSubmit = event => {
      
      event.preventDefault();
      
      if(this.props.length > 0){
        let b0 = this.props.b0;
      }
      if(this.props.length > 1){
        let b1 = this.props.b1;
      }
      if(this.props.length > 2){
        let b2 = this.props.b2;
      }
      if(this.props.length > 3){
        let b3 = this.props.b3;
      }
      if(this.props.length > 4){
        let b4 = this.props.b4;
      }
      if(this.props.length > 5){
        let b5 = this.props.b5;
      }
      if(this.props.length > 6){
        let b6 = this.props.b6;
      }
      let answerpre = 'let pl, pw, Fc, Fy, v';
      if(this.props.type == 'M'){
        answerpre += ', Fcd';
      }
      if(this.props.id == 'Capacity-as-built-closed-forms_1'){
        if(this.props.type == 'M'){
            answerpre += ', R;';
            answerpre += 'pl = ' + this.state.pl + '; Fcd = ' + this.state.Fcd + '; pw = ' + this.state.pw + '; R = ' + this.state.R + '; Fc = ' + this.state.Fc + '; Fy = ' + this.state.Fy + '; v = ' + this.state.v + '; ';
        }else{
            answerpre += ', D;';
            answerpre += 'pl = ' + this.state.pl + '; pw = ' + this.state.pw + '; D = ' + this.state.D + '; Fc = ' + this.state.Fc + '; Fy = ' + this.state.Fy + '; v = ' + this.state.v + '; ';
        }
        
      }else if(this.props.id == 'Capacity-as-built-closed-forms_2'){
        if(this.props.type == 'M'){
            answerpre += ', Dout, Din, Rout, Rin;';
            answerpre += 'pl = ' + this.state.pl + '; Fcd = ' + this.state.Fcd + '; pw = ' + this.state.pw + '; Dout = ' + this.state.Dout + '; Din = ' + this.state.Din + '; Rout = ' + this.state.Rout + '; Rin = ' + this.state.Rin + '; Fc = ' + this.state.Fc + '; Fy = ' + this.state.Fy + '; v = ' + this.state.v + '; ';
        }else{
            answerpre += ', Dout, Din;';
            answerpre += 'pl = ' + this.state.pl + '; pw = ' + this.state.pw + '; Dout = ' + this.state.Dout + '; Din = ' + this.state.Din + '; Fc = ' + this.state.Fc + '; Fy = ' + this.state.Fy + '; v = ' + this.state.v + '; ';
        }
      }else if(this.props.id == 'Capacity-as-built-closed-forms_3' || this.props.id == 'Capacity-as-built-closed-forms_5'){
        answerpre += ', h, b;';
        answerpre += 'pl = ' + this.state.pl + '; pw = ' + this.state.pw + '; h = ' + this.state.h + '; b = ' + this.state.b + '; Fc = ' + this.state.Fc + '; Fy = ' + this.state.Fy + '; v = ' + this.state.v + '; ';
        if(this.props.type == 'M'){
            answerpre += 'Fcd = ' + this.state.cd + '; ';
        }
      }else if(this.props.id == 'Capacity-as-built-closed-forms_4'){
        answerpre += ', h, b, t;';
        answerpre += 'pl = ' + this.state.pl + '; pw = ' + this.state.pw + '; h = ' + this.state.h + '; t = ' + this.state.t + '; b = ' + this.state.b + '; Fc = ' + this.state.Fc + '; Fy = ' + this.state.Fy + '; v = ' + this.state.v + '; ';
        if(this.props.type == 'M'){
            answerpre += 'Fcd = ' + this.state.cd + '; ';
        }
      }
      let equation = this.props.relationship;
      console.log(equation);
      let answerstring = answerpre + equation;
      let answer = eval(answerstring);
      answer = (Math.round(answer * 1000000) / 1000000).toFixed(6);

      this.setState({
        result: answer,
      })
  
    }
    
  
    
    render(){
        let Fcd, R, D, Dout, Din, Rout, Rin, h, b, t;
        if(this.props.type == 'M'){
            Fcd = <label>
            f<sub>cd</sub> (MPa):
            <input
                type="text"
                name="Fcd"
                value={this.state.Fcd}
                onChange={this.handleInputChange}
            />
            </label>;
        }

        if(this.props.id == 'Capacity-as-built-closed-forms_1'){
            if(this.props.type == 'M'){
                R = <label>
                R (m):
                <input
                    type="text"
                    name="R"
                    value={this.state.R}
                    onChange={this.handleInputChange}
                />
                </label>;
            }else{
                D = <label>
                D: (m)
                <input
                    type="text"
                    name="D"
                    value={this.state.D}
                    onChange={this.handleInputChange}
                />
                </label>;
            }
            
          }else if(this.props.id == 'Capacity-as-built-closed-forms_2'){
            if(this.props.type == 'M'){
                Dout = <label>
                D<sub>out</sub> (m):
                <input
                    type="text"
                    name="Dout"
                    value={this.state.Dout}
                    onChange={this.handleInputChange}
                />
                </label>;
                Din = <label>
                D<sub>in</sub> (m):
                <input
                    type="text"
                    name="Din"
                    value={this.state.Din}
                    onChange={this.handleInputChange}
                />
                </label>;
                Rout = <label>
                R<sub>out</sub> (m):
                <input
                    type="text"
                    name="Rout"
                    value={this.state.Rout}
                    onChange={this.handleInputChange}
                />
                </label>;
                Rin = <label>
                R<sub>in</sub> (m):
                <input
                    type="text"
                    name="Rin"
                    value={this.state.Rin}
                    onChange={this.handleInputChange}
                />
                </label>;
            }else{
                Dout = <label>
                D<sub>out</sub> (m):
                <input
                    type="text"
                    name="Dout"
                    value={this.state.Dout}
                    onChange={this.handleInputChange}
                />
                </label>;
                Din = <label>
                D<sub>in</sub> (m):
                <input
                    type="text"
                    name="Din"
                    value={this.state.Din}
                    onChange={this.handleInputChange}
                />
                </label>;
            }
          }else if(this.props.id == 'Capacity-as-built-closed-forms_3' || this.props.id == 'Capacity-as-built-closed-forms_5'){
            h = <label>
            h (m):
            <input
                type="text"
                name="h"
                value={this.state.h}
                onChange={this.handleInputChange}
            />
            </label>;
            b = <label>
            b (m):
            <input
                type="text"
                name="b"
                value={this.state.b}
                onChange={this.handleInputChange}
            />
            </label>;
          }else if(this.props.id == 'Capacity-as-built-closed-forms_4'){
            h = <label>
            h (m):
            <input
                type="text"
                name="h"
                value={this.state.h}
                onChange={this.handleInputChange}
            />
            </label>;
            b = <label>
            b (m):
            <input
                type="text"
                name="b"
                value={this.state.b}
                onChange={this.handleInputChange}
            />
            </label>;
            t = <label>
            t (m):
            <input
                type="text"
                name="t"
                value={this.state.t}
                onChange={this.handleInputChange}
            />
            </label>;
          }
        
      return(
          <>
        <form onSubmit={this.handleSubmit}>
        <label>
        ρ<sub>l</sub>:
        <input
            type="text"
            name="pl"
            value={this.state.pl}
            onChange={this.handleInputChange}
        />
        </label>
        <label>
        ρ<sub>w</sub>:
        <input
            type="text"
            name="pw"
            value={this.state.pw}
            onChange={this.handleInputChange}
        />
        </label>
        {D}
        {Dout}
        {Din}
        {b}
        {h}
        {t}
        <label>
        f<sub>c</sub> (MPa):
        <input
            type="text"
            name="Fc"
            value={this.state.Fc}
            onChange={this.handleInputChange}
        />
        </label>
        <label>
        f<sub>y</sub> (MPa):
        <input
            type="text"
            name="Fy"
            value={this.state.Fy}
            onChange={this.handleInputChange}
        />
        </label>
        <label>
        v:
        <input
            type="text"
            name="v"
            value={this.state.v}
            onChange={this.handleInputChange}
        />
        </label>
        {Fcd}
        {R}
        {Rout}
        {Rin}
    
        <button type="submit">Calculate</button>
    </form>
    <div className="result"><span dangerouslySetInnerHTML={{__html: this.props.param}} /> : {this.state.result}</div>
    </>
      )
    }
}
export default Formrelation
